<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="6">
        <v-autocomplete
          label="Periodo"
          item-text="nombre"
          item-value="id"
          :items="periodos"
          v-model="periodoId"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-autocomplete
          label="Grupos"
          item-text="nombre"
          item-value="id"
          :items="grupos"
          v-model="grupoId"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-for="(avance, index) in avances" :key="index">
        <v-card>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title class="text-h5">
                {{avance.nombreAsginatura}}
              </v-list-item-title>
              <v-list-item-subtitle
                >{{avance.nombreDocente}}</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
          <v-card-text>
            <v-progress-linear striped :value="avance.avance"  color="light-green darken-4" height="25">
            <strong style="color:white">{{ Math.ceil(avance.avance) }}%</strong>
          </v-progress-linear>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions } from "vuex";
export default {
  data: () => ({
    periodoId: 0,
    grupoId: 0,
    periodos: [],
    grupos: [],
    avances: [],
    skill: 20,
    knowledge: 33,
    power: 78,
  }),
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions(["GetAvancesNotas", "GetPeriodos", "GetGrupos"]),
    initialize() {
      this.GetPeriodos().then((x) => (this.periodos = x.data));
    },
  },
  watch: {
    periodoId(nuevoValor) {
      if (nuevoValor > 0) {
        this.GetGrupos().then((x) => (this.grupos = x.data));
      } else {
        this.grupos = [];
      }
    },
    grupoId(nuevoValor) {
      if (nuevoValor > 0 && this.periodoId > 0) {
        let datos = {};
        datos.periodo = this.periodoId;
        datos.grupo = nuevoValor;
        this.GetAvancesNotas(datos).then((x) => (this.avances = x.data));
      }
    },
  },
};
</script>