<template>
    <AvanceNotas/>
</template>
<script>
import AvanceNotas from "@/components/AvanceNotas";
export default {
    components: {
    AvanceNotas,
  },
}
</script>